:root {
  font-family: "Nunito", sans-serif;
}

body {
  font-family: "Nunito", sans-serif;
}

.tab-content {
  .contact {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
  }

  .contact-item {
    padding: 20px;
  }
}

fieldset {
  border: 1px solid $secondary;
  border-radius: 2px;
  padding: 0 16px 16px 16px;
  margin-bottom: 16px;

  legend {
    display: inline-block;
    width: auto;
    font-size: 1.2rem;
    padding-left: 8px;
    padding-right: 8px;
  }

  h4 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.education-item,
.experience-item,
.migration-item {
  margin-bottom: 16px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.ant-table-tbody > tr > td {
  padding: 0;
}

.ant-table-tbody > tr > td > div {
  padding: 4px;
}

.ant-table-tbody > tr > td div:hover {
  cursor: pointer;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.avatar-uploader > .ant-upload {
  width: 150px;
  height: 150px;
}

ul.table-explanation {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;

  li {
    display: flex;
    align-items: center;
    margin-right: 16px;

    span {
      display: inline-block;
      width: 30px;
      height: 20px;
      margin-right: 8px;
    }
  }

  li.status-ok span {
    background-color: limegreen;
  }

  li.status-late span {
    background-color: yellow;
  }

  li.status-incomplete span {
    background-color: orange;
  }

  li.status-absent span {
    background-color: red;
  }
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: 18px;
}

.alert--danger {
  margin-top: 4px;
}

.card-header,
.card-header--body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-footer {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 4px;
  }
}

.profile-tooltip {
  display: flex;

  img {
    align-self: center;
    margin-right: 16px;
  }
}

.profile-tooltip--body {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .icon {
      margin-right: 8px;
    }
  }
}

.report {
  font-family: Arial, Helvetica, sans-serif;

  h3 {
    margin: 0 5rem;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
  }
}

.table--report--bordered {
  border: 1px solid #ccc;
}

.report-headline {
  font-size: 0.7rem;
}

.report-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 3px double black;

  h1,
  p {
    font-weight: 700;
  }
}

.table--report {
  width: 100%;

  td {
    padding: 10px 20px;
  }
}

.table--head {
  text-align: center;
  border-collapse: separate;
  border-spacing: 0 10px;
}
.ant-tree .ant-tree-node-content-wrapper {
line-height: 1.5715;
}
