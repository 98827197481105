.react-datepicker-popper {
    z-index: 9999 !important;
}
.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}
.react-search-field {
    width: 400px;
}
.custom-react-search-field {
    width: 300px;
}
.custom-ant-table {
    width: 800px;
}