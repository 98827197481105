@media only screen and (max-width: 992px) {
  .tab-content {
    .contact {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media only screen and (max-width: 576px) {
  .tab-content {
    .contact {
      grid-template-columns: 1fr;
    }
  }
}
